// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Cfencodedecode3 from "../../blocks/cfencodedecode3/src/Cfencodedecode3";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Videos from "../../blocks/videos/src/Videos";
import Contentdeliverynetworkcdnonserver from "../../blocks/contentdeliverynetworkcdnonserver/src/Contentdeliverynetworkcdnonserver";
import Cfdrmintegration2 from "../../blocks/cfdrmintegration2/src/Cfdrmintegration2";
import Cfbandsrails from "../../blocks/cfbandsrails/src/Cfbandsrails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Cfmultimediafilesupport2 from "../../blocks/cfmultimediafilesupport2/src/Cfmultimediafilesupport2";


const routeMap = {
Cfencodedecode3:{
 component:Cfencodedecode3,
path:"/Cfencodedecode3"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Videos:{
 component:Videos,
path:"/Videos"},
Contentdeliverynetworkcdnonserver:{
 component:Contentdeliverynetworkcdnonserver,
path:"/Contentdeliverynetworkcdnonserver"},
Cfdrmintegration2:{
 component:Cfdrmintegration2,
path:"/Cfdrmintegration2"},
Cfbandsrails:{
 component:Cfbandsrails,
path:"/Cfbandsrails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Cfmultimediafilesupport2:{
 component:Cfmultimediafilesupport2,
path:"/Cfmultimediafilesupport2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;